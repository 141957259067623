
<template>
  <div class="login">
    <s-header :name="$t('t.signIn')" :back="'/home'"></s-header>
    <img class="logo" :src="logoImage" alt="">
    <van-row class="paddTop20 textCenter textCenter size24 fontB paddBott30">{{projectName}}</van-row>
    <div class="login-body register">
      <van-form @submit="onSubmit">
        <van-field
          v-model="username1"
          name="username1"
          :label="$t('t.username')"
          :placeholder="$t('t.username')"
          :rules="[{ required: true, message: $t('t.placeholder4')}]"
        />
        <van-field
          v-model="password1"
          type="password"
          name="password1"
          :label="$t('t.password')"
          :placeholder="$t('t.password')"
          :rules="[{ required: true, message: $t('t.placeholder5') }]"
        />
        <div class="verify">
          <Verify ref="registerVerifyRef" @error="error" :showButton="false" @success="success" :width="'100%'" :height="'40px'" :codeLength="4" :type="1"></Verify>
        </div>
        <van-checkbox
          style="margin-top: 10px;" icon-size="14px"
          v-model="checked" shape="square">我已经阅读并同意<span class="colorBlue" @click.stop="$router.push('/agreement')">《用户注册协议》</span>，<span class="colorBlue" @click.stop="$router.push('/privacyAgreement')">《隐私政策》</span>
        </van-checkbox>
        <div style="margin: 16px;">
          <van-button round block type="info" color="#e92d74" native-type="submit">{{$t('t.signIn')}}</van-button>
          <div class="link-login"  @click="$router.push('/login')">{{$t('t.alreadyLoggedAccount')}}</div>
        </div>
      </van-form>
    </div>
    <van-row class="padding20 app" type="flex" align="center" justify="center">
      <div class="googleRegister" id="googleRegister"></div>
    </van-row>
    <van-row class="color24 size14 paddBott60 paddTop30 textCenter copyRightBox"><span style="font-family:Arial;">深圳暴富先生科技</span>2024版权所有&nbsp;
      <a href="https://beian.miit.gov.cn/" class="colorBlue" target="_self">粤ICP备2024204260号</a>
    </van-row>
    <google-register
      :invitationCode="invitationCode"
      :googleEmail="googleEmail"
      :googleRegisterBool="googleRegisterBool"
      @googleRegisterBackFn="googleRegisterBool = false">
    </google-register>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import {register,selectConfig ,googleLogin} from '../service/user'
import { setLocal, getLocal,getQueryString } from '@/common/js/utils'
import Verify from 'vue2-verify'
import googleRegister from '../components/googleRegister'
export default {
  data() {
    return {
      username1: '',
      password1: '',
      verify: false,
      checked:false,
      logoImage: '',
      projectName: '',
      invitationCode:'',
      googleEmail:'',
      googleRegisterBool:false,
    }
  },
  components: {
    sHeader,
    Verify,
    googleRegister
  },
  async created(){

    window.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id:'262405584003-24gia4qmh47cnjj24p1evugajth29hlp.apps.googleusercontent.com',
        auto_select: false,
        callback: this.handleCredentialResponse,
      });
      // 设置按钮的样式等
      window.google.accounts.id.renderButton(
        document.getElementById("googleRegister"),
        {
          theme: "filled_blue",
          size: 'large',
          width:'250',
          type: 'standard',
          text: 'signin_with',
          locale:"en",
        }
      );
    })

    this.invitationCode = getQueryString('invitationCode') || localStorage['invitationCode'] || ''
    if(this.invitationCode) localStorage['invitationCode'] = this.invitationCode
    const { data, resultCode } = await selectConfig()
    this.logoImage = data.logoImage
    this.projectName = data.projectName
    document.getElementById('linkIcon').href = data.logoImage
    document.title = data.projectName
  },
  methods: {
    async handleCredentialResponse(response) {
      // 获取回调响应的凭证数据 然后拿这个凭证给后台，后台jwt进行解析获取登录信息
      let credential = response.credential
      const { data } = await googleLogin({credential});
      if(data){
        if(data.includes('@')){
        this.googleEmail = data
        this.googleRegisterBool = true
        }else{
          setLocal('token', data)
          window.location.href = '/'
          this.$toast.success(this.$t('t.registeredSuccessfully'))
        }
      }else{
        this.$toast.fail(this.$t('t.googleAuthorizationFailed'))
      }

    },
    dealTriVer() {
      // 执行验证码的验证，通过 this.verify 知道验证码是否填写正确
      this.$refs.registerVerifyRef.$refs.instance.checkCode()
    },
    async onSubmit(values) {
      await this.dealTriVer()
      if (!this.verify) {
        this.$toast.fail(this.$t('t.placeholder6'))
        return
      }

      if (!this.checked) {
        this.$toast.fail('请阅读并同意《用户注册协议》和《隐私政策》')
        return
      }
      this.$toast.loading({ message: this.$t('t.loading'), forbidClick: true });
      const { data } = await register({
        "loginName": values.username1,
        "password": values.password1,
        invitationCode:this.invitationCode
      })
      this.$toast.success(this.$t('t.registeredSuccessfully'))

      setLocal('token', data)
      this.$toast.clear()
      window.location.href = '/'
      // this.$router.push('/login')
    },
    success(obj) {
      this.verify = true
      // 回调之后，刷新验证码
      obj.refresh()
    },
    error(obj) {
      this.verify = false
      // 回调之后，刷新验证码
      obj.refresh()
    }
  },
}
</script>

<style lang="less">
  .login {
    .logo {
      width: 120px;
      /*height: 120px;*/
      display: block;
      margin: 80px auto 0px;
    }
    .login-body {
      padding: 0 20px;
    }
    .login {
      .link-register {
        font-size: 14px;
        margin-top: 20px;
        color: #1989fa;
        display: inline-block;
        float: right;
      }
    }
    .register {
      .link-login {
        font-size: 14px;
        margin-top: 20px;
        color: #1989fa;
        display: inline-block;
        float: right;
      }
    }
    .verify-bar-area {
      margin-top: 24px;
      .verify-left-bar {
        border-color: #e92d74;
      }
      .verify-move-block {
        background-color: #e92d74;
        color: #fff;
      }
    }
    .verify {
      >div {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      .cerify-code-panel {
        margin-top: 16px;
      }
      .verify-code {
        width: 40%!important;
        float: left!important;
      }
      .verify-code-area {
        float: left!important;
        width: 54%!important;
        margin-left: 14px!important;
        .varify-input-code {
          width: 90px;
          height: 38px!important;
          border: 1px solid #e9e9e9;
          padding-left: 10px;
          font-size: 16px;
        }
        .verify-change-area {
          line-height: 44px;
        }
      }
    }
  }
  .colorBlue{
    color: #e92d74;
  }
</style>
